var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.studentData.length > 0)?_c('div',[_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"studentship_status",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s((row.studentship_status === 'left') ? 'WITHDRAWN' : row.studentship_status.toUpperCase())+" ")])}},{key:"parent_name",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.student_guardian.guardian.user.first_name + ' ' + row.student_guardian.guardian.user.last_name)+" ")])}},{key:"parent_phone",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.student_guardian.guardian.user.phone1 + ' | ' + row.student_guardian.guardian.user.phone2)+" ")])}},{key:"parent_email",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.student_guardian.guardian.user.email)+" ")])}},{key:"action",fn:function(props){return _c('div',{},[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('View Details'),expression:"'View Details'",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"}},[_c('router-link',{staticStyle:{"color":"#fff"},attrs:{"to":{name: 'studentDetails', params: {id: props.row.id}}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)],1),(_vm.checkRole(['super']))?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Reset Password'),expression:"'Reset Password'",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.resetPassword(props.row.user)}}},[_c('feather-icon',{attrs:{"icon":"UnlockIcon"}})],1)],1):_vm._e(),(_vm.checkRole(['super']))?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Login as ' + props.row.user.first_name),expression:"'Login as ' + props.row.user.first_name",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"dark"},on:{"click":function($event){return _vm.loginAsUser(props.row.user)}}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1)],1):_vm._e()])}}],null,false,392133094),model:{value:(_vm.studentData),callback:function ($$v) {_vm.studentData=$$v},expression:"studentData"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }